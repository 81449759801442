var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};

(function (root, factory) {
  exports = factory();
})(exports, function () {
  'use strict'; // Public: Create a new SelectorSet.

  function SelectorSet() {
    // Construct new SelectorSet if called as a function.
    if (!((this || _global) instanceof SelectorSet)) {
      return new SelectorSet();
    } // Public: Number of selectors added to the set


    (this || _global).size = 0; // Internal: Incrementing ID counter

    (this || _global).uid = 0; // Internal: Array of String selectors in the set

    (this || _global).selectors = []; // Internal: Map of selector ids to objects

    (this || _global).selectorObjects = {}; // Internal: All Object index String names mapping to Index objects.

    (this || _global).indexes = Object.create((this || _global).indexes); // Internal: Used Object index String names mapping to Index objects.

    (this || _global).activeIndexes = [];
  } // Detect prefixed Element#matches function.


  var docElem = window.document.documentElement;
  var matches = docElem.matches || docElem.webkitMatchesSelector || docElem.mozMatchesSelector || docElem.oMatchesSelector || docElem.msMatchesSelector; // Public: Check if element matches selector.
  //
  // Maybe overridden with custom Element.matches function.
  //
  // el       - An Element
  // selector - String CSS selector
  //
  // Returns true or false.

  SelectorSet.prototype.matchesSelector = function (el, selector) {
    return matches.call(el, selector);
  }; // Public: Find all elements in the context that match the selector.
  //
  // Maybe overridden with custom querySelectorAll function.
  //
  // selectors - String CSS selectors.
  // context   - Element context
  //
  // Returns non-live list of Elements.


  SelectorSet.prototype.querySelectorAll = function (selectors, context) {
    return context.querySelectorAll(selectors);
  }; // Public: Array of indexes.
  //
  // name     - Unique String name
  // selector - Function that takes a String selector and returns a String key
  //            or undefined if it can't be used by the index.
  // element  - Function that takes an Element and returns an Array of String
  //            keys that point to indexed values.
  //


  SelectorSet.prototype.indexes = []; // Index by element id

  var idRe = /^#((?:[\w\u00c0-\uFFFF\-]|\\.)+)/g;
  SelectorSet.prototype.indexes.push({
    name: "ID",
    selector: function matchIdSelector(sel) {
      var m;

      if (m = sel.match(idRe)) {
        return m[0].slice(1);
      }
    },
    element: function getElementId(el) {
      if (el.id) {
        return [el.id];
      }
    }
  }); // Index by all of its class names

  var classRe = /^\.((?:[\w\u00c0-\uFFFF\-]|\\.)+)/g;
  SelectorSet.prototype.indexes.push({
    name: "CLASS",
    selector: function matchClassSelector(sel) {
      var m;

      if (m = sel.match(classRe)) {
        return m[0].slice(1);
      }
    },
    element: function getElementClassNames(el) {
      var className = el.className;

      if (className) {
        if (typeof className === "string") {
          return className.split(/\s/);
        } else if (typeof className === "object" && "baseVal" in className) {
          // className is a SVGAnimatedString
          // global SVGAnimatedString is not an exposed global in Opera 12
          return className.baseVal.split(/\s/);
        }
      }
    }
  }); // Index by tag/node name: `DIV`, `FORM`, `A`

  var tagRe = /^((?:[\w\u00c0-\uFFFF\-]|\\.)+)/g;
  SelectorSet.prototype.indexes.push({
    name: "TAG",
    selector: function matchTagSelector(sel) {
      var m;

      if (m = sel.match(tagRe)) {
        return m[0].toUpperCase();
      }
    },
    element: function getElementTagName(el) {
      return [el.nodeName.toUpperCase()];
    }
  }); // Default index just contains a single array of elements.

  SelectorSet.prototype.indexes["default"] = {
    name: "UNIVERSAL",
    selector: function () {
      return true;
    },
    element: function () {
      return [true];
    }
  }; // Use ES Maps when supported

  var Map;

  if (typeof window.Map === "function") {
    Map = window.Map;
  } else {
    Map = function () {
      function Map() {
        (this || _global).map = {};
      }

      Map.prototype.get = function (key) {
        return (this || _global).map[key + " "];
      };

      Map.prototype.set = function (key, value) {
        (this || _global).map[key + " "] = value;
      };

      return Map;
    }();
  } // Regexps adopted from Sizzle
  //   https://github.com/jquery/sizzle/blob/1.7/sizzle.js
  //


  var chunker = /((?:\((?:\([^()]+\)|[^()]+)+\)|\[(?:\[[^\[\]]*\]|['"][^'"]*['"]|[^\[\]'"]+)+\]|\\.|[^ >+~,(\[\\]+)+|[>+~])(\s*,\s*)?((?:.|\r|\n)*)/g; // Internal: Get indexes for selector.
  //
  // selector - String CSS selector
  //
  // Returns Array of {index, key}.

  function parseSelectorIndexes(allIndexes, selector) {
    allIndexes = allIndexes.slice(0).concat(allIndexes["default"]);
    var allIndexesLen = allIndexes.length,
        i,
        j,
        m,
        dup,
        rest = selector,
        key,
        index,
        indexes = [];

    do {
      chunker.exec("");

      if (m = chunker.exec(rest)) {
        rest = m[3];

        if (m[2] || !rest) {
          for (i = 0; i < allIndexesLen; i++) {
            index = allIndexes[i];

            if (key = index.selector(m[1])) {
              j = indexes.length;
              dup = false;

              while (j--) {
                if (indexes[j].index === index && indexes[j].key === key) {
                  dup = true;
                  break;
                }
              }

              if (!dup) {
                indexes.push({
                  index: index,
                  key: key
                });
              }

              break;
            }
          }
        }
      }
    } while (m);

    return indexes;
  } // Internal: Find first item in Array that is a prototype of `proto`.
  //
  // ary   - Array of objects
  // proto - Prototype of expected item in `ary`
  //
  // Returns object from `ary` if found. Otherwise returns undefined.


  function findByPrototype(ary, proto) {
    var i, len, item;

    for (i = 0, len = ary.length; i < len; i++) {
      item = ary[i];

      if (proto.isPrototypeOf(item)) {
        return item;
      }
    }
  } // Public: Log when added selector falls under the default index.
  //
  // This API should not be considered stable. May change between
  // minor versions.
  //
  // obj - {selector, data} Object
  //
  //   SelectorSet.prototype.logDefaultIndexUsed = function(obj) {
  //     console.warn(obj.selector, "could not be indexed");
  //   };
  //
  // Returns nothing.


  SelectorSet.prototype.logDefaultIndexUsed = function () {}; // Public: Add selector to set.
  //
  // selector - String CSS selector
  // data     - Optional data Object (default: undefined)
  //
  // Returns nothing.


  SelectorSet.prototype.add = function (selector, data) {
    var obj,
        i,
        indexProto,
        key,
        index,
        objs,
        selectorIndexes,
        selectorIndex,
        indexes = (this || _global).activeIndexes,
        selectors = (this || _global).selectors,
        selectorObjects = (this || _global).selectorObjects;

    if (typeof selector !== "string") {
      return;
    }

    obj = {
      id: (this || _global).uid++,
      selector: selector,
      data: data
    };
    selectorObjects[obj.id] = obj;
    selectorIndexes = parseSelectorIndexes((this || _global).indexes, selector);

    for (i = 0; i < selectorIndexes.length; i++) {
      selectorIndex = selectorIndexes[i];
      key = selectorIndex.key;
      indexProto = selectorIndex.index;
      index = findByPrototype(indexes, indexProto);

      if (!index) {
        index = Object.create(indexProto);
        index.map = new Map();
        indexes.push(index);
      }

      if (indexProto === (this || _global).indexes["default"]) {
        this.logDefaultIndexUsed(obj);
      }

      objs = index.map.get(key);

      if (!objs) {
        objs = [];
        index.map.set(key, objs);
      }

      objs.push(obj);
    }

    (this || _global).size++;
    selectors.push(selector);
  }; // Public: Remove selector from set.
  //
  // selector - String CSS selector
  // data     - Optional data Object (default: undefined)
  //
  // Returns nothing.


  SelectorSet.prototype.remove = function (selector, data) {
    if (typeof selector !== "string") {
      return;
    }

    var selectorIndexes,
        selectorIndex,
        i,
        j,
        k,
        selIndex,
        objs,
        obj,
        indexes = (this || _global).activeIndexes,
        selectors = (this || _global).selectors = [],
        selectorObjects = (this || _global).selectorObjects,
        removedIds = {},
        removeAll = arguments.length === 1;
    selectorIndexes = parseSelectorIndexes((this || _global).indexes, selector);

    for (i = 0; i < selectorIndexes.length; i++) {
      selectorIndex = selectorIndexes[i];
      j = indexes.length;

      while (j--) {
        selIndex = indexes[j];

        if (selectorIndex.index.isPrototypeOf(selIndex)) {
          objs = selIndex.map.get(selectorIndex.key);

          if (objs) {
            k = objs.length;

            while (k--) {
              obj = objs[k];

              if (obj.selector === selector && (removeAll || obj.data === data)) {
                objs.splice(k, 1);
                removedIds[obj.id] = true;
              }
            }
          }

          break;
        }
      }
    }

    for (i in removedIds) {
      delete selectorObjects[i];
      (this || _global).size--;
    }

    for (i in selectorObjects) {
      selectors.push(selectorObjects[i].selector);
    }
  }; // Sort by id property handler.
  //
  // a - Selector obj.
  // b - Selector obj.
  //
  // Returns Number.


  function sortById(a, b) {
    return a.id - b.id;
  } // Public: Find all matching decendants of the context element.
  //
  // context - An Element
  //
  // Returns Array of {selector, data, elements} matches.


  SelectorSet.prototype.queryAll = function (context) {
    if (!(this || _global).selectors.length) {
      return [];
    }

    var matches = {},
        results = [];
    var els = this.querySelectorAll((this || _global).selectors.join(", "), context);
    var i, j, len, len2, el, m, match, obj;

    for (i = 0, len = els.length; i < len; i++) {
      el = els[i];
      m = this.matches(el);

      for (j = 0, len2 = m.length; j < len2; j++) {
        obj = m[j];

        if (!matches[obj.id]) {
          match = {
            id: obj.id,
            selector: obj.selector,
            data: obj.data,
            elements: []
          };
          matches[obj.id] = match;
          results.push(match);
        } else {
          match = matches[obj.id];
        }

        match.elements.push(el);
      }
    }

    return results.sort(sortById);
  }; // Public: Match element against all selectors in set.
  //
  // el - An Element
  //
  // Returns Array of {selector, data} matches.


  SelectorSet.prototype.matches = function (el) {
    if (!el) {
      return [];
    }

    var i, j, k, len, len2, len3, index, keys, objs, obj, id;
    var indexes = (this || _global).activeIndexes,
        matchedIds = {},
        matches = [];

    for (i = 0, len = indexes.length; i < len; i++) {
      index = indexes[i];
      keys = index.element(el);

      if (keys) {
        for (j = 0, len2 = keys.length; j < len2; j++) {
          if (objs = index.map.get(keys[j])) {
            for (k = 0, len3 = objs.length; k < len3; k++) {
              obj = objs[k];
              id = obj.id;

              if (!matchedIds[id] && this.matchesSelector(el, obj.selector)) {
                matchedIds[id] = true;
                matches.push(obj);
              }
            }
          }
        }
      }
    }

    return matches.sort(sortById);
  }; // Public: Export SelectorSet


  return SelectorSet;
});

export default exports;